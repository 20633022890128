<template>
  <div class="container-fluid">
    <div class="row home-header">
      <div class="offset-1 col-4">
        <img src="/logo.png" class="home-logo" alt="Логотип сайта"/>
        <h2>Федеральный регистр товарных электронных сертификатов</h2>
      </div>
      <div class="col-auto">
        <a href="https://www.stopklon.ru">Вернуться на главную страницу</a>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <div class="row check-info">
          <h6>Все товары, зарегистрированные в федеральном регистре
            товарных электронны сертификатов снабжены биркой, на которой имеется
            уникальный идентификационный номер.<br>
            Под скретч-полосой имеется код доступа, позволяющий удостовериться в
            надёжности данного товара и получить сертификат.
          </h6>
          <img src="/birka.png" style="height: 240px; width: 180px;" alt="Бирка"/>
          <p>Для получения официального сертификата подлинности продукта достаточно трёх шагов.</p>
          <div v-if="startChecking" class="offset-1">
            <img src="/step1.png" alt="Диаграмма шагов"/>
          </div>
          <div v-else-if="checkVerificationCode" class="offset-1">
            <img src="/step2.png" alt="Диаграмма шагов"/>
          </div>
          <div v-else-if="waitForPrint" class="offset-1">
            <img src="/step3.png" alt="Диаграмма шагов"/>
          </div>
          <div class="w-100" style="height: 20px"/>
          <div v-if="startChecking" class="offset-1">
            <label for="identifier">Идентификационный номер:</label>
            <input id="identifier" v-model="form.id" v-maska="'####-####-####-####'" @maska="onMaskId">
            <font-awesome-icon id="cameraIcon" icon="camera" size="2x" v-on:click="startScan"
                               title="Сканирование qr-кода"/>
          </div>
          <div v-else-if="checkVerificationCode" class="offset-1">
            <label for="checkingCode">Проверочный код:</label>
            <input id="checkingCode" v-model="form.code" v-maska="'####-####'" @maska="verifyCode">
          </div>
          <div v-else-if="waitForPrint" class="offset-1">
            <button class="btn btn-primary" @click="printCertificate">Напечатать сертификат</button>
            <button class="btn btn-secondary" @click="returnToStart">Закрыть</button>
          </div>
          <div class="w-100" style="height: 20px"/>
        </div>
      </div>
      <div class="col-6">
        <div v-if="startChecking">
          <h4 class="cert-info">
            В поле для ввода номера нужно ввести идентификационный номер, указанный на бирке,
            расположенной на проверяемом товаре. Если ваше устройство снабжено видеокамерой,
            то можно с её помощью отсканировать QR-код (нажав на кнопку с символом камеры),
            так же расположенный на бирке.<br>
            При наличии данного кода в федеральном регистре товарных электронных сертификатов
            Вам будет предоставлена имеющаяся в федеральном регистре информация, указанная
            производителем товара.<br>
            Для получения сертификата, подтверждающего подлинность данной единицы продукции,
            необходимо стереть скретч-полосу на бирке и ввести в поле код, расположенный под
            скретч-полосой.<br>
            В случае подлинности бирки (или выданного ранее сертификата) Вам будет отображаться
            новый сертификат, подтверждающий подлинность данной единицы продукции.
            При последующих проверках нужно вводить идентификационный номер (до символа "/")
            данного сертификата и последний указанный на сертификате проверочный код.
          </h4>
        </div>
        <div v-else-if="checkVerificationCode">
          <div class="card">
            <h2 class="card-title">{{productInfo.name}}</h2>
            <img v-bind:src="productInfo.imageUrl" class="card-img" alt="Фотография продукта отсутствует ">
            <p class="card-text"><span v-html="productInfo.description"/></p>
          </div>
        </div>
        <div v-else-if="waitForPrint">
          <pdf :src="pdfSrc">
            <template #loading>
              Сертификат загружается ...
            </template>
          </pdf>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="scanQR" aria-labelledby="scanQRtitle">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="scanQRtitle">Поднесите бирку для сканирования</h2>
          <button type="button" class="btn-close" v-on:click="cancelScan" aria-label="Закрыть"/>
        </div>
        <div v-if="scanQRactive" class="modal-body">
          <qrcode-stream @decode="onDecode"/>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="error" aria-labelledby="errorTitle">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header alert alert-danger" role="alert">
          <h2 class="modal-title" id="errorTitle">{{errorTitle}}</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"/>
        </div>
        <p class="modal-body" style="text-align: left">{{errorMessage}}</p>
        <p v-if="errorFooter != null" class="modal-footer alert alert-warning">{{errorFooter}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty";
import {Modal} from 'bootstrap';
import printJS from 'print-js';
import {markRaw} from "vue";
import {maska} from "maska";
import pdf from "vue3-pdf";

export default {
  name: 'StartPage',
  components: {
    pdf
  },
  data: function () {
    return {
      form: {
        id: undefined,
        code: undefined
      },
      headerContent: markRaw(Empty),
      scanQRactive: false,
      scanQRmodal: null,
      errorModal: null,
      errorTitle: 'Ошибка',
      errorFooter: null,
      errorMessage: ""
    }
  },
  directives: { maska },
  computed: {
    startChecking() { return this.$store.state.check.startChecking; },
    checkVerificationCode() { return this.$store.state.check.checkVerificationCode; },
    verificationResult() { return this.$store.state.check.verificationResult; },
    waitForPrint() { return this.$store.state.check.waitForPrint; },
    productInfo() { return this.$store.state.check.productInfo; },
    pdfSrc() { return this.$store.state.check.certificateUrl; }
  },
  methods: {
    cancelScan() {
      this.scanQRactive = false;
      this.scanQRmodal.hide();
    },
    getProductInfo(id) {
      this.$store.dispatch("getProductInfo", id).catch((e) => {
        this.errorMessage = "Вы возможно ошиблись при вводе идентификационного номера, " +
            "внимательно введите цифры номера и повторите попытку.\n" +
            "Если Вы уверены в том что не допустили ошибки или вы сканировали бирку, то " +
            "это означает, что товар НЕ ВНЕСЁН в федеральный регистр товарных электронных сертификатов " +
            "и лучше воздержаться от его приобретения.";
        this.errorFooter = e;
        this.errorModal.show();
      });
    },
    onDecode(payload) {
      this.errorFooter = null;
      this.errorMessage = null;
      this.scanQRactive = false;
      this.scanQRmodal.hide();
      this.form.id = payload;
      this.getProductInfo(payload);
    },
    onMaskId(event) {
      if(event.target.dataset.maskRawValue.length >= 16) {
        this.getProductInfo(event.target.dataset.maskRawValue);
        this.errorMessage = null;
        this.errorFooter = null;
      }
    },
    printCertificate() { printJS(this.pdfSrc, 'pdf');
      this.$store.dispatch("updateVerificationCode", this.form.code);
    },
    startScan() {
      this.scanQRactive = true;
      this.scanQRmodal.show();
    },
    returnToStart() {
      this.$store.dispatch("returnToStart");
      this.form.code = undefined;
      this.form.id = undefined;
    },
    verifyCode(event) {
      if(event.target.dataset.maskRawValue.length >= 8) {
        this.errorFooter = null;
        this.errorMessage = null;
        this.$store.dispatch("verifyCode", this.form.code)
            .catch((e) => { this.errorFooter = e;
              this.errorMessage = "Возникла техническая ошибка при проверке.\nПопробуйте повторить проверку позднее."
              this.errorModal.show();
            }).then(() => { switch (this.verificationResult.checkResult) {
          case 'SUCCESS': this.$store.dispatch("getCertificate", this.form.code)
              .catch((e) => { this.errorFooter = e;
                this.errorMessage = "Возникла техническая ошибка при проверке.\nПопробуйте повторить проверку позднее."
                this.errorModal.show();
              });
            break;
          case 'NONEXIST_CODE': this.form.code = null;
            this.errorMessage="Введённый код доступа не соответствует зарегистрированному в " +
                "федеральном регистре товарных электронных сертификатов.\nПроверьте правильность " +
                "ввода кода доступа и если ошибок нет то рекомендуем воздержаться от его приобретения."
            this.errorModal.show();
            break;
          case 'NONEXIST_NUMBER': this.form.code = null;
            this.errorMessage="Идентификационный номер данного товара не зарегистрирован в реестре подлинных товаров, " +
                "рекомендуем воздержаться от приобретения данного товара."
            this.errorModal.show();
            break;
          case 'HISTORICAL_CODE': this.form.id = null; this.form.code = null;
            this.errorMessage="Введённый код доступа был уже ранее использован для проведения проверки.\n" +
                "Правильный код доступа указан на последнем выданном сертификате, " +
                "рекомендуем воздержаться от приобретения данного товара."
            this.errorModal.show();
            this.$store.dispatch("returnToStart");
            break;
          case 'NOTACTIVATE': this.form.id = null; this.form.code = null;
            this.errorMessage="Идентификационный номер данного товара не был активирован производителем, " +
                "рекомендуем воздержаться от приобретения данного товара."
            this.errorModal.show();
            this.$store.dispatch("returnToStart");
            break;
          case 'REJECT': this.form.id = null; this.form.code = null;
            this.errorMessage="Введённый код доступа не соответствует зарегистрированному в " +
                "федеральном регистре товарных электронных сертификатов.\nПроверьте правильность " +
                "ввода кода доступа и если ошибок нет то рекомендуем воздержаться от его приобретения."
            this.errorModal.show();
            this.$store.dispatch("returnToStart");
            break;
          default:
            this.errorMessage = "Возникла техническая ошибка при проверке.\nПопробуйте повторить проверку позднее."
            this.errorModal.show();
            break;
        }});
      }
    }
  },
  mounted() {
    this.scanQRmodal = new Modal(document.getElementById("scanQR"));
    this.errorModal = new Modal(document.getElementById("error"));
  }
}
</script>

<style scoped>
.cert-info {
  text-align: justify;
}
.home-logo {
  padding-right: 10px;
  max-height: 100px;
  min-height: 20px;
  margin-top: 5px;
  float: left;
}
.check-info {
  background-color: rgb(255, 255, 255);
  text-align: left;
  padding: 10px;
  margin: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
