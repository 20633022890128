import "bootstrap/dist/css/bootstrap.min.css";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueQrcodeReader from "vue3-qrcode-reader";
import {createApp} from 'vue';
import {store} from "@/store";
import router from "@/router";
import App from './App.vue';
import "bootstrap";

library.add(faCamera);

createApp(App).use(store).use(router).use(VueQrcodeReader).
component("font-awesome-icon", FontAwesomeIcon).
mount('#app');