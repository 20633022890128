import Axios from 'axios';

export default {
    state: {
        productInfo: null,
        startChecking: true,
        certificateUrl: null,
        checkVerificationCode: false,
        verificationResult: null,
        waitForPrint: false
    },
    mutations: {
        showStartPage: (state) => {
            state.startChecking = true;
            state.waitForPrint = false;
            state.checkVerificationCode = false;
            state.verificationResult = null;
            state.certificateUrl = null;
            state.productInfo = null;
        },
        showProductInfo: (state, payload) => {
            state.checkVerificationCode = true;
            state.verificationResult = null;
            state.startChecking = false;
            state.waitForPrint = false;
            state.productInfo = payload;
        },
        showCertificate: (state, payload) => {
            state.checkVerificationCode = false;
            state.verificationResult = null;
            state.startChecking = false;
            state.waitForPrint = true;
            state.certificateUrl = payload;
        },
        setVerificationResult: (state, payload) => {
            state.verificationResult = payload;
        }
    },
    actions: {
        getProductInfo: async (context, payload) => {
            let {data} = await Axios.get(process.env.VUE_APP_CHECKING_URL + "productInfo/" + payload);
            context.commit('showProductInfo', data);
        },
        verifyCode: async (context, payload) => {
            let {data} = await Axios.post(process.env.VUE_APP_CHECKING_URL + "verify/" +
                context.state.productInfo.id + "?code=" + payload);
            context.commit('setVerificationResult', data);
        },
        getCertificate: async (context, payload) => {
            context.commit('showCertificate', process.env.VUE_APP_CHECKING_URL + "certificate/" +
                context.state.productInfo.id + "/" + payload);
        },
        returnToStart: async (context) => { context.commit('showStartPage', context.state); },
        updateVerificationCode: async (context, payload) => {
            await Axios.post(process.env.VUE_APP_CHECKING_URL + "newCode/" +
                context.state.productInfo.id + "/" + payload);
        }
    }
};