import Vuex from 'vuex';
import check from './modules/check';

export const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        check
    }
});